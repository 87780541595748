import gql from "graphql-tag";

export const FORGOT_PASS = gql`
  mutation forgotPw($email: String!) {
    forgetPassword(forgetPasswordInput: { email: $email }) {
      id
      email
      name
      token
    }
  }
`;

export const M_EDIT_PASSWORD = gql`
  mutation updatePassword($id: ID!, $old: String!, $pass: String!) {
    updatePassword(id: $id, oldPassword: $old, newPassword: $pass) {
      id
    }
  }
`;
export const M_EDIT_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $phone: String
    $email: String
    $ugdn: String
    $role: roleType
    $profile: String
    $detail: [CreateDetailUserInput!]
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        name: $name
        phone: $phone
        email: $email
        ugdn: $ugdn
        role: $role
        profile: $profile
        detail: $detail
      }
    ) {
      id
      name
      phone
      profile
    }
  }
`;

export const M_EDIT_OUTSTANDING = gql`
  mutation feedOutstanding(
    $id: ID!
    $from: String
    $to: String
    $region: Int
    $dokumentasi: String
    $feedback: String
  ) {
    updateOutstanding(
      updateOutstandingInput: {
        id: $id
        from: $from
        to: $to
        region_id: $region
        dokumentasi: $dokumentasi
        feedback: $feedback
      }
    ) {
      id
    }
  }
`;
export const M_PBLS_OUTSTANDING = gql`
  mutation sawOutstanding($id: String!) {
    publishOutstanding(publishOutstandingInput: { id: $id, isSaw: true }) {
      id
    }
  }
`;

export const M_ADD_COLLECTION = gql`
  mutation addCollection(
    $target: String
    $tanggal: DateTime!
    $tagihan: String!
  ) {
    createCollection(
      createCollectionInput: {
        target_id: $target
        tanggal: $tanggal
        tagihan: $tagihan
      }
    ) {
      id
    }
  }
`;
export const M_PBLS_TARGETCOL = gql`
  mutation publishTargetCol($id: String!) {
    publishTargetCollection(
      publishTargetCollectionInput: { id: $id, isReported: true }
    ) {
      id
    }
  }
`;
export const M_EDIT_COLLECTION = gql`
  mutation editCollection(
    $id: ID!
    $target: String
    $tanggal: DateTime
    $tagihan: String
  ) {
    updateCollection(
      updateCollectionInput: {
        id: $id
        target_id: $target
        tanggal: $tanggal
        tagihan: $tagihan
      }
    ) {
      id
    }
  }
`;
export const VALIDATE_OTP = gql`
  mutation newPw($email: String!, $password: String!, $otpCode: String!) {
    validateOTP(
      validateOtpInput: {
        email: $email
        password: $password
        otpCode: $otpCode
      }
    ) {
      id
      name
    }
  }
`;
export const M_EDIT_TARGET = gql`
  mutation updateTarget(
    $id: ID!
    $from: String
    $to: String
    $month: String
    $year: String
    $country: Int
    $region: Int
    $prov: String
    $kab: String
    $produk: String
    $price: Int
    $qty: Int
    $value: String
    $tipe: SalesTargetType
  ) {
    updateTarget(
      updateTargetInput: {
        id: $id
        from: $from
        to: $to
        target_quantity: $qty
        target_value: $value
        month: $month
        year: $year
        country_id: $country
        region_id: $region
        prov_id: $prov
        kab_id: $kab
        produk_id: $produk
        price: $price
        tipe_target: $tipe
      }
    ) {
      id
      produk_id
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

// MATPRO TYPES
export const createMatproType = gql`
  mutation createMatproType($jenis_matpro: String!) {
    createJenisMaterialPromosi(input: { jenis_matpro: $jenis_matpro }) {
      id
    }
  }
`;

export const editMatproType = gql`
  mutation editMatproType($id: ID!, $matpro: String!) {
    updateJenisMaterialPromosi(input: { id: $id, matpro: $matpro }) {
      id
    }
  }
`;

export const delMatproType = gql`
  mutation deleteMatproType($id: ID!) {
    deleteJenisMaterialPromosi(id: $id)
  }
`;

// MATERIAL PROMOSI
export const createMatpro = gql`
  mutation createMatpro(
    $jenisMatproId: String!
    $produkId: String!
    $stock: Int!
  ) {
    createMaterialPromosi(
      input: {
        jenisMatproId: $jenisMatproId
        produkId: $produkId
        stock: $stock
      }
    ) {
      id
    }
  }
`;

export const updateMatpro = gql`
  mutation updateMatpro($id: ID!, $jenisMatproId: String!, $produkId: String!) {
    updateMaterialPromosi(
      input: { id: $id, jenisMatproId: $jenisMatproId, produkId: $produkId }
    ) {
      id
    }
  }
`;

export const deleteMatpro = gql`
  mutation deleteMatpro($id: ID!) {
    deleteMaterialPromosi(id: $id)
  }
`;

export const addMatproStock = gql`
  mutation addStockMatpro($id: ID!, $stock: Int!) {
    addStockMaterialPromosi(input: { id: $id, stock: $stock }) {
      id
    }
  }
`;

export const reduceMatproStock = gql`
  mutation reduceMatproStock($id: ID!, $stock: Int!) {
    minusStockMaterialPromosi(input: { id: $id, stock: $stock }) {
      id
    }
  }
`;

export const publishMatpro = gql`
  mutation publishMatpro($id: ID!, $isMatpro: Boolean!) {
    publishIsMatpro(input: { id: $id, isMatpro: $isMatpro }) {
      id
    }
  }
`;

export const publishSample = gql`
  mutation publishSample($id: ID!, $isSample: Boolean!) {
    publishIsSample(input: { id: $id, isSample: $isSample }) {
      id
    }
  }
`;

// PROPOSAL REQUEST
export const createProposalRequest = gql`
  mutation createProposalRequest(
    $periode: String!
    $tipe: InventoryType!
    $userId: String!
    $jumlahSP: Int!
    $detail: [CreateDetailRequestProposalInput!]
  ) {
    createRequestProposal(
      input: {
        userId: $userId
        tipe: $tipe
        periode: $periode
        jumlahSP: $jumlahSP
        detail: $detail
      }
    ) {
      id
    }
  }
`;

export const editProposalRequest = gql`
  mutation editProposalRequest(
    $id: ID!
    $periode: String
    $tipe: InventoryType!
    $userId: String!
    $jumlahSP: Int!
    $detail: [CreateDetailRequestProposalInput!]
  ) {
    updateRequestProposal(
      input: {
        id: $id
        userId: $userId
        jumlahSP: $jumlahSP
        tipe: $tipe
        periode: $periode
        detail: $detail
      }
    ) {
      id
    }
  }
`;

export const delProposalRequest = gql`
  mutation deleteReqProposal($id: ID!) {
    deleteRequestProposal(id: $id)
  }
`;

export const publishProposalRequest = gql`
  mutation publishReqProposal(
    $id: ID!
    $status: statusProposalType!
    $userId: String!
  ) {
    publishRequestProposal(
      input: { id: $id, status: $status, userId: $userId }
    ) {
      id
    }
  }
`;

export const publishDetailProposalPr = gql`
  mutation publishDetailProposalPr(
    $id: ID!
    $status: statusProposalType!
    $kebutuhanSampleApproved: Float!
  ) {
    publishRequestProposalOneByOneForProcess(
      input: {
        id: $id
        kebutuhanSampleApproved: $kebutuhanSampleApproved
        status: $status
      }
    ) {
      id
      requestId
    }
  }
`;

export const publishDetailProposalAp = gql`
  mutation publishDetailProposalAp(
    $id: ID!
    $status: statusProposalType!
    $kebutuhanSampleApproved: Float!
  ) {
    publishRequestProposalOneByOneForApproved(
      input: {
        id: $id
        kebutuhanSampleApproved: $kebutuhanSampleApproved
        status: $status
      }
    ) {
      id
      requestId
    }
  }
`;

// ACCOUNT REQUEST
export const delAccountRequest = gql`
  mutation deleteReqAccount($id: ID!) {
    deleteRequestAccount(id: $id)
  }
`;

export const publishAccountRequest = gql`
  mutation publishReqAccount($id: ID!, $status: Boolean!) {
    publishRequestAccount(input: { id: $id, status: $status }) {
      id
    }
  }
`;

// INVENTORIES
export const breakdownInventory = gql`
  mutation breakdownInventory(
    $userId: String!
    $tipe: InventoryType!
    $breakdownItem: [BreakdownItemInput!]!
  ) {
    breakdownInventory(
      input: { userId: $userId, tipe: $tipe, breakdownItem: $breakdownItem }
    ) {
      id
    }
  }
`;

export const createPeriod = gql`
  mutation createPeriod($rangePeriode: Int!) {
    createPeriode(input: { rangePeriode: $rangePeriode }) {
      id
    }
  }
`;

export const updatePeriod = gql`
  mutation updatePeriod(
    $id: ID!
    $startPeriode: String!
    $endPeriode: String!
  ) {
    updatePeriode(
      input: { id: $id, startPeriode: $startPeriode, endPeriode: $endPeriode }
    ) {
      id
    }
  }
`;

export const delPeriod = gql`
  mutation delPeriod($id: ID!) {
    deletePeriode(id: $id)
  }
`;

export const createMultipleReport = gql`
  mutation createMultipleReport(
    $from: String
    $day_report: DateTime
    $month: String
    $year: String
    $item: [ItemProdukInput!]!
  ) {
    createLaporanMulti(
      input: {
        from: $from
        day_report: $day_report
        month: $month
        year: $year
        item: $item
        # country_id: ""
        # region_id: ""
        # prov_id: ""
      }
    ) {
      id
    }
  }
`;
