<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("modulsam.htitle") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-1 mb-3">
            <b-col>
              <h6 class="ssp-24">{{ $t("modulsam.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right" v-if="activeTab === 'BREAKDOWN'">
              <button
                class="btn btn-success btn-add mr-4"
                @click="onAdd('BREAKDOWN')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("modulsam.bd.add") }}
              </button>
            </b-col>
            <b-col cols="6" class="text-right" v-if="activeTab === 'REQUEST'">
              <button
                class="btn btn-success btn-add mr-4"
                @click="onAdd('REQUEST')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("modulsam.req.add") }}
              </button>
            </b-col>
          </div>
          <section class="m-3">
            <b-tabs content-class="mt-3" class="tab-custom">
              <b-tab
                :title="$t('modulsam.tab.inv')"
                title-link-class="sp-report-tabs"
                active
                @click="setTabs('INVENTORY')"
              >
                <ListMatpro />
              </b-tab>
              <b-tab
                :title="$t('modulsam.tab.bd')"
                title-link-class="sp-report-tabs"
                @click="setTabs('BREAKDOWN')"
              >
                <BreakdownMatpro />
              </b-tab>
              <b-tab
                :title="$t('modulsam.tab.req')"
                title-link-class="sp-report-tabs"
                @click="setTabs('REQUEST')"
              >
                <RequestProposal />
              </b-tab>
            </b-tabs>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import ListMatpro from "./List.vue";
import BreakdownMatpro from "./Breakdown.vue";
import RequestProposal from "./Request.vue";

export default {
  components: {
    Card,
    ListMatpro,
    BreakdownMatpro,
    RequestProposal
  },
  data() {
    return {
      activeTab: null
    };
  },
  methods: {
    setTabs(value) {
      this.activeTab = value;
    },
    onAdd(value) {
      switch (value) {
        case "BREAKDOWN":
          this.$store.commit("setTitle", this.$t("modulsam.bds"));
          this.$store.commit("changeModalBreakdown", true);
          break;

        case "REQUEST":
          this.$store.commit("setTitle", this.$t("modulsam.rps"));
          this.$store.commit("changeModalRequest", true);
          break;
      }
    }
  },
  mounted() {
    this.activeTab = "INVENTORY";
  }
};
</script>

<style>
.sp-report-tabs.active {
  color: #000 !important;
  background-color: #f3f3f3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid #fd8833 !important;
}

.sp-report-tabs {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #273b4a;
}

.tab-custom div .nav-tabs {
  background-color: #f3f3f3;
  border-radius: 8px;
}
</style>
