<template>
  <section>
    <div class="row ml-0 mr-0 table-utilities">
      <div class="row ml-2 w-100">
        <div class="col-md-12 search-section">
          <div class="input-group">
            <!-- <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div> -->
            <input
              type="text"
              class="form-control search-input"
              placeholder=""
              @input="setPage"
              v-model="filterInput"
              disabled
            />
          </div>
        </div>
        <!-- <div class="col-md-2 pl-0">
          <select class="custom-select" v-model="filters.userId">
            <option
              v-for="user in salesPromotors"
              :key="user.id"
              :value="user.id"
            >
              {{ user.name }}
            </option>
          </select>
        </div> -->
        <!-- <div class="col-md-2 pl-0">
          <div
            class="btn table-button ssp-14-700 export-button h-100 d-flex align-items-center"
            @click="handleExport"
          >
            <div class="font-12">
              <i class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </div>
        </div> -->
      </div>
    </div>
    <!-- {{ visibility }} -->
    <div class="table-borderless">
      <b-table-simple hover responsive style="overflow: hidden;">
        <b-thead class="bv-head">
          <b-tr>
            <b-th>#</b-th>
            <b-th v-for="hd in $t('modulsam.bd.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
            <!-- <b-th>Action</b-th> -->
          </b-tr>
        </b-thead>
        <b-tbody v-if="breakdowns.length !== 0">
          <b-tr
            class="ssp-16-400"
            v-for="(bd, index) in breakdowns"
            :key="bd.id"
          >
            <b-td>
              {{ ++index + (currPage - 1) * perPage }}
            </b-td>
            <b-td>
              {{ dateFr(bd.tanggalBreakdown, "DD MMMM YYYY") }}
            </b-td>
            <b-td>
              {{ bd.tipe }}
            </b-td>
            <b-td>{{ bd.users.name }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="7">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          <!-- 1 - 6 of {{ this.totalData }} -->
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{ orange: currPage < totalPages }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="visibility"
      id="modal-laporan"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="closeModal()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit.prevent="onSubmit">
          <section v-for="(form, index) in forms" :key="form.id">
            <div class="form-group">
              <label for="breakdown-spiv" class="typo__label ssp-16 text-black">
                {{ $t("modulsam.bd.label.mp") }}
              </label>
              <span
                v-if="index > 0"
                class="float-right mr-1 rm-mp pointer"
                @click="removeBreakdown(index)"
              >
                {{ $t("global.delete") }}
              </span>
              <multiselect
                :placeholder="$t('modulsam.bd.placeholder.mp')"
                v-model="form.inventoryId"
                :options="inventories.map(inv => inv.id)"
                :custom-label="
                  opt => `
                    ${inventories.find(x => x.id === opt).produks.brand}
                    (${inventories.find(x => x.id === opt).jumlah})
                  `
                "
                :class="{
                  'invalid-form rounded-input':
                    $v.forms.$each[index].inventoryId.$error
                }"
                :allow-empty="false"
                id="breakdown-spiv"
              />
            </div>
            <b-row v-for="(sp, idx) in form.breakdownSP" :key="sp.id">
              <b-col>
                <div class="form-group">
                  <label
                    for="breakdown-spsp"
                    class="typo__label ssp-16 text-black"
                  >
                    {{ $t("modulsam.bd.label.sp") }}
                  </label>
                  <multiselect
                    id="breakdown-spsp"
                    :placeholder="$t('modulsam.bd.placeholder.sp')"
                    v-model="sp.userId"
                    :options="salesPromotors.map(sp => sp.id)"
                    :class="{
                      'invalid-form rounded-input':
                        $v.forms.$each[index].breakdownSP.$each[idx].userId
                          .$error
                    }"
                    :custom-label="
                      opt => salesPromotors.find(x => x.id === opt).name
                    "
                  />
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <label
                    for="breakdown-jumlah"
                    class="typo__label ssp-16 text-black"
                  >
                    {{ $t("modulsam.bd.label.bd") }}
                  </label>
                  <input
                    id="breakdown-jumlah"
                    class="form-control bd-jml"
                    :placeholder="$t('modulsam.bd.placeholder.bd')"
                    type="number"
                    :class="{
                      'is-invalid':
                        $v.forms.$each[index].breakdownSP.$each[idx].jumlah
                          .$error
                    }"
                    v-model="sp.jumlah"
                  />
                </div>
              </b-col>
            </b-row>
            <div col md="5" class="pr-2">
              <b-row>
                <b-col cols="11">
                  <a
                    class="btn btn-success-outline ssp-14 w-100 my-3 py-2"
                    @click="addSP(index)"
                  >
                    {{ $t("modulsam.bd.addsp") }}
                  </a>
                </b-col>
                <b-col cols="1" class="pl-0">
                  <span
                    class="btn btn-secondary my-3 py-2"
                    @click="removeSP(index)"
                    style="font-size: 13px; box-shadow: none !important;"
                  >
                    &#10006;
                  </span>
                </b-col>
              </b-row>
            </div>
            <hr class="mt-1" />
          </section>
          <b-row>
            <b-col col md="5" class="pr-2">
              <a
                class="btn btn-success ssp-14 w-100 my-3 py-2"
                @click="addBreakdown"
              >
                {{ $t("modulsam.bd.addbd") }}
              </a>
            </b-col>
            <b-col col md="7" class="pl-2">
              <button
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("modulsam.bd.submit") }}
              </button>
            </b-col>
          </b-row>
        </form>
      </template>
    </b-modal>
  </section>
</template>

<script>
// import JwtService from "@/core/services/jwt.service";
import Multiselect from "vue-multiselect";
import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
// import { BaseInput } from "@/components";
import jwtService from "@/core/services/jwt.service";
import { RoleType, inventoryType } from "@/graphql/enum.type";
import {
  Q_LIST_USERSEARCH,
  listBreakdowns,
  listInventories
} from "@/graphql/queries";
import * as XLSX from "xlsx";
import { required } from "vuelidate/lib/validators";
import { breakdownInventory } from "@/graphql/mutations";

export default {
  data() {
    return {
      forms: [
        {
          breakdownSP: [{}]
        }
      ],
      breakdowns: [],
      inventories: [],
      salesPromotors: [],
      filters: {},

      currPage: 1,
      perPage: 10,
      totalData: null,
      totalPages: null,
      filterInput: "",
      optionRole: [RoleType.SP],
      inventoryTypes: [inventoryType.MATPRO, inventoryType.SAMPLE]
    };
  },
  components: {
    // BaseInput,
    Multiselect
  },
  apollo: {
    listBreakdown: {
      query: () => listBreakdowns,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          userId: jwtService.getID(),
          tipe: this.inventoryTypes[1]
          // orderBy: ,
          // sortBy: SortByType!
        };
      },
      result({ data }) {
        this.breakdowns = data.listBreakdown.breakdowns;
        this.totalPages = data.listBreakdown.totalPage;
        this.totalData = data.listBreakdown.total;
      }
    },
    listInventory: {
      query: () => listInventories,
      variables() {
        return {
          page: 1,
          limit: 50,
          keyword: this.filterInput,
          userId: jwtService.getID(),
          produkId: "",
          matproId: "",
          tipe: this.inventoryTypes[1],
          isGetFromMobile: false,
          orderBy: "CREATED_AT",
          sortBy: "DESC"
        };
      },
      result({ data }) {
        this.inventories = data.listInventory.inventorys;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: "",
          role: this.optionRole[0],
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [jwtService.getID()],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.salesPromotors = data.listUser.users;
      }
    }
  },
  validations: {
    forms: {
      $each: {
        inventoryId: { required },
        breakdownSP: {
          $each: {
            userId: { required },
            jumlah: { required }
          }
        }
      }
    }
  },
  methods: {
    addBreakdown() {
      this.forms.push({
        breakdownSP: [{}]
      });
    },
    removeBreakdown(index) {
      this.forms.splice(index, 1);
    },
    addSP(index) {
      this.forms[index].breakdownSP.push({});
    },
    removeSP(index) {
      this.forms[index].breakdownSP.pop();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.$v.$reset();
      this.forms = [
        {
          breakdownSP: [{}]
        }
      ];
    },
    closeModal() {
      this.$store.commit("changeModalBreakdown", false);
      this.resetForm();
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.forms.map(a => {
          a.breakdownSP.map(b => {
            b.jumlah = parseInt(b.jumlah);
          });
        });
        return new Promise((resolve, reject) => {
          const breakdown = this.$apollo.mutate({
            mutation: breakdownInventory,
            variables: {
              userId: jwtService.getID(),
              tipe: this.inventoryTypes[1],
              breakdownItem: this.forms
            }
          });
          if (resolve) {
            resolve(breakdown);
          } else {
            reject(breakdown);
          }
        })
          .then(response => {
            if (response) {
              this.$apollo.queries.listBreakdown.refetch();
              this.toastAlert("Berhasil breakdown sample");
              this.closeModal();
            }
          })
          .catch(err => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${err.message.split(": ")[2]} (${
                err.message.split(": ")[3]
              })`,
              width: "26em",
              confirmButtonColor: "#fd8833"
            });
          });
      }
    },
    async handleExport() {
      this.isLoading = true;
      this.perPage = this.totalData;
      this.currPage = 1;
      setTimeout(() => {
        const filter = [];
        // for (let i = 0; i < this.breakdowns.length; i++) {
        //   let negara, wilayah, provinsi, kab, kec, kel, uCrop, oCrop;
        //   if (this.breakdowns[i].countrys != null) {
        //     negara = this.breakdowns[i].countrys.country;
        //   }
        //   if (this.breakdowns[i].regions != null) {
        //     wilayah = this.breakdowns[i].regions.region;
        //   }
        //   if (this.breakdowns[i].prov != null) {
        //     provinsi = this.breakdowns[i].prov.province;
        //   }
        //   if (this.breakdowns[i].kab != null) {
        //     kab = this.breakdowns[i].kab.kabupaten;
        //   }
        //   if (this.breakdowns[i].kec != null) {
        //     kec = this.breakdowns[i].kec.kecamatan;
        //   }
        //   if (this.breakdowns[i].kel != null) {
        //     kel = this.breakdowns[i].kel.kelurahan;
        //   }
        //   if (this.breakdowns[i].ucrops != null) {
        //     uCrop = this.breakdowns[i].ucrops.crop;
        //   }
        //   if (this.breakdowns[i].ocrops != null) {
        //     oCrop = this.breakdowns[i].ocrops.crop;
        //   }
        //   filter.push({
        //     name: this.breakdowns[i].name,
        //     sp: this.breakdowns[i].sp.name,
        //     tss: this.breakdowns[i].tss.name,
        //     country: negara,
        //     region: wilayah,
        //     province: provinsi,
        //     kabupaten: kab,
        //     kecamatan: kec,
        //     kelurahan: kel,
        //     alamat: this.breakdowns[i].alamat,
        //     hp: this.breakdowns[i].hp,
        //     luasHA: this.breakdowns[i].luas,
        //     tutama: uCrop,
        //     tlain: oCrop
        //   });
        // }
        const data = XLSX.utils.json_to_sheet(filter);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "Rencana Bulanan");
        XLSX.writeFile(wb, "monthlyplan.xlsx");
        this.perPage = 10;
      }, 2000);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.$store.getters.modalBreakdown;
      },
      set(value) {
        return value;
      }
    },
    modalTitle: {
      get() {
        return this.$store.getters.modalTitle;
      },
      set(value) {
        return value;
      }
    }
  },
  mounted() {}
};
</script>

<style>
.custom-select {
  background-color: #fff;
  border-radius: 8px;
}

.bd-jml {
  border: 1px solid #e8e8e8;
}
</style>
